import React from "react";

import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch
} from "@elastic/react-search-ui";

// // SGXX
import ResultView from "./ResultView";

import { Layout } from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields
} from "./config/config-helper";


const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig()
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector
  //alwaysSearchOnInitialLoad: true //SGXX
};

//export default function App() {
class App extends React.Component {
  state = {};

  togglePlay = () => {
    console.log('Loading');
  }

  render() {
    return (
      <SearchProvider config={config}>
        <WithSearch mapContextToProps={({ wasSearched, resultSearchTerm }) => ({ wasSearched, resultSearchTerm })}>
          {({ wasSearched, resultSearchTerm }) => {
            return (
              <div className="App">
                <ErrorBoundary>
                  {/* <SearchBox autocompleteSuggestions={true} /> */}
                  <SearchBox
                    autocompleteSuggestions={true}
                    inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
                      <>
                        <div className="sui-search-box__wrapper">
                          <input
                            {...getInputProps({
                              placeholder: "Search transcripts and show notes"
                            })}
                          />
                          {getAutocomplete()}
                        </div>

                      </>
                    )}
                  />
                  {(wasSearched && (resultSearchTerm !== "")) ?
                    <Layout
                      // header={<SearchBox autocompleteSuggestions={true} />}
                      header={
                        <div className="columns is-centered is-mobile">
                          <div className="column is-narrow">
                            <a className="button is-small is-info is-outlined" href="/">Clear Search</a>
                          </div>
                        </div>
                      }
                      sideContent={
                        (wasSearched && (resultSearchTerm !== "")) ?
                          <div>
                            {wasSearched && (resultSearchTerm !== "") && (
                              <Sorting
                                label={"Sort by"}
                                sortOptions={buildSortOptionsFromConfig()}
                              />
                            )}
                            {getFacetFields().map(field => (
                              <Facet key={field} field={field} label={field} />
                            ))}
                          </div> : <div></div>
                      }
                      bodyContent={
                        (wasSearched && (resultSearchTerm !== "")) ?
                          <Results
                            titleField={getConfig().titleField}
                            urlField={getConfig().urlField}
                            shouldTrackClickThrough={true}
                            resultView={ResultView}
                          /> : <div></div>
                      }
                      bodyHeader={
                        (wasSearched && (resultSearchTerm !== "")) ?
                          <React.Fragment>
                            {wasSearched && <PagingInfo />}
                            {wasSearched && <ResultsPerPage />}
                          </React.Fragment> : <div></div>
                      }
                      bodyFooter={
                        (wasSearched && (resultSearchTerm !== "")) ?
                          <Paging /> : <div></div>
                      }
                    /> : <div></div>
                  }
                </ErrorBoundary>
              </div>
            );
          }}
        </WithSearch>
      </SearchProvider>
    );
  }
}

export default App;